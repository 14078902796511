<template>
  <div class="row">
    <div class="col-md-12">
      <div
        id="filter-card"
        class="row justify-content-start items-baseline w-100 mb-2"
        style="margin-bottom: -5px"
      >
        <span
          class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2"
          @click="$router.go(-1)"
        >
          <i class="fa fa-chevron-left"></i>
        </span>

        <h3 class="d-inline-block" id="page-title">{{ $t("setting.notification") }}</h3>
      </div>
      <dashboard-box>
        <template v-slot:preview>
          <div
            class="row px-10 py-1 cursor-pointer"
            v-for="(value, index) in notifications"
          >
            <div
              class="d-flex w-100 items-center navy-blue-border-1px border-15px px-3 py-2 mb-4 relative"
              @click="onClickNotification(value)"
            >
              <span
                v-if="value.readed != 1"
                class="absolute bg-danger w-20px h-20px rounded-full text-center text-white"
                style="
                  top: 0;
                  right: 0;
                  line-height: 20px;
                  margin-top: -7px;
                  margin-right: -7px;
                "
              >
                1
              </span>
              <span
                v-html="
                  getIconByKey('icons.model_house.notifications', {
                    class: 'w-50px h-50px object-fill mr-3 d-line-block',
                  })
                "
              ></span>
              <div class="flex-grow-1 text-lg">
                <div class="text-xl">{{ value["text"] }}</div>
                <div class="flex-grow-1 text-lg">
                  <div class="text-xl">{{ 
                    moment(value["created_at"], LARAVEL_DATE_FORMAT).format('DD.MM.YYYY HH:MM')                  
                  }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row px-10 py-1 cursor-pointer" v-if="notifications.length < 1">
            <div
              class="d-flex w-100 items-center navy-blue-border-1px border-15px px-3 py-2 mb-4 relative"
              @click="$router.push({ name: value.route_name, params: value.params })"
            >
              <span
                v-html="
                  getIconByKey('icons.model_house.notifications', {
                    class: 'w-50px h-50px object-fill mr-3 d-line-block',
                  })
                "
              ></span>
              <div class="flex-grow-1 text-lg">
                <div class="text-xl">{{ $t("general.no_notification") }}</div>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from "vuex";
import {
  CREATE_ITEM,
  GET_ITEMS as REST_GET_ITEM,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/REST.module";
import { LOGOUT } from "@/core/services/store/auth.module";
import moment from "moment";
import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
export default {
  name: "Notification",
  components: {
    DashboardBox,
  },
  computed: {
    ...mapGetters({}),
    moment: () => moment,
  },
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    onClickNotification(value) {
      this.$store.dispatch(CREATE_ITEM, {
        url: `api/notification/${value.id}/read`,
      });
      this.$router.push({ name: value.route_name, params: value.params });
    },
  },

  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "Modal" },
    ]);

    this.$store
      .dispatch(REST_GET_ITEM, {
        url: "api/notification",
        acceptPromise: true,
        filters: {},
      })
      .then((result) => {
        if (result.status) {
          // result.data=result.data.forEach((element,key) => {
          //   result.data[key]['date']=moment(element.created_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT);
          // });
          self.notifications = result.data;
        }
      });
  },
};
</script>

<style scoped></style>
